<template>
  <header class="md-layout-header">
    <md-shared-header />
  </header>
</template>

<script>
import MdSharedHeaderComponent from '../header.vue'
export default {
  name: 'MdLayoutHeaderComponent',
  components: {
    'md-shared-header': MdSharedHeaderComponent
  },
  data () {
    return {
      user: this.$local.getItem('MD_USER')
    }
  }
}
</script>
